define('norme-adresse/components/com-search', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Component.extend({
        res: [],
        lru: new LRUMap(100),
        chosen: {},
        konami: 0,
        pop: false,
        keyUp: function keyUp(e) {
            if (this.get('query').length > 3) {
                this.set('isAutocomplete', 1);
                if (this.lru.has(this.get('query'))) {
                    _ember.default.set(this.res, 'features', this.lru.get(this.get('query')));
                } else {
                    var result = this.res;
                    var lruhash = this.lru;
                    var query = this.get('query');
                    _ember.default.$.ajax({
                        dataType: "json",
                        url: this.connInfo.url + this.get('query'),
                        headers: {
                            "x-api-key": this.connInfo.key
                        }
                    }).then(function (data) {
                        _ember.default.set(result, 'features', data.features);
                        lruhash.set(query, data.features);
                    });
                }
            } else {
                this.set('isAutocomplete', 0);
                switch (e.keyCode) {
                    case 38:
                        {
                            if (this.konami < 2) {
                                this.konami++;
                            } else {
                                this.konami = 0;
                            }
                            break;
                        }
                    case 40:
                        {
                            if (this.konami > 1 && this.konami < 4) {
                                this.konami++;
                            } else {
                                this.konami = 0;
                            }
                            break;
                        }
                    case 37:
                        {
                            if (this.konami === 4 || this.konami === 6) {
                                this.konami++;
                            } else {
                                this.konami = 0;
                            }
                            break;
                        }
                    case 39:
                        {
                            if (this.konami === 5 || this.konami === 7) {
                                this.konami++;
                            } else {
                                this.konami = 0;
                            }
                            break;
                        }
                    case 66:
                        {
                            if (this.konami === 8) {
                                this.konami++;
                            } else {
                                this.konami = 0;
                            }
                            break;
                        }
                    case 65:
                        {
                            if (this.konami === 9) {
                                this.konami++;
                            } else {
                                this.konami = 0;
                            }
                            break;
                        }
                    default:
                        {
                            this.konami = 0;
                            break;
                        }
                }
                if (this.konami === 10) {
                    _ember.default.Logger.log('Cheater Never Prosper');
                    this.toggleProperty('pop');
                }
            }
        },

        actions: {
            choseData: function choseData(e) {
                _ember.default.set(this.chosen, 'feature', this.res.features[e]);
                this.set('query', this.chosen.feature.properties.label);
                this.set('isAutocomplete', 0);
                this.sendAction();
                _ember.default.Logger.log(this.connInfo.env);
            }
        }
    });
});