define('norme-adresse/controllers/map', ['exports', 'ember', 'norme-adresse/config/environment'], function (exports, _ember, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var isEmpty = _ember.default.isEmpty;
    exports.default = _ember.default.Controller.extend({
        init: function init() {
            if (isEmpty(this.connInfo)) {
                this.connInfo = {
                    "url": _environment.default.APP.PROD_MAP_HOST,
                    "key": _environment.default.APP.PROD_KEY,
                    "env": "prod"
                };
            }
            var query = '35 Rue du Clos Four 63100 Clermont-Ferrand';
            var result = this.chosen;
            _ember.default.$.ajax({
                dataType: "json",
                url: this.connInfo.url + query,
                headers: {
                    "x-api-key": this.connInfo.key
                }
            }).then(function (data) {
                _ember.default.set(result, 'feature', data.features[0]);
                _ember.default.Logger.log(data);
                _ember.default.Logger.log(result);
            });
        },
        lat: 48.8575325,
        lng: 2.3391225,
        zoom: 13,
        pop: false,
        chosen: { "feature": {
                "type": "Feature",
                "properties": {
                    "commune_grdf": {
                        "code_concession": "75109",
                        "publication": "oui",
                        "nom": "Paris 9e",
                        "code": "75109",
                        "libelle_acheminement": "PARIS 9E"
                    },
                    "city": "TEST",
                    "housenumber": "6",
                    "postcode": "75009",
                    "street": "Rue Condorcet",
                    "name": "6 Rue Condorcet",
                    "id": "ADRNIVX_0000000270730878",
                    "libelle_acheminement": "PARIS",
                    "score": 0.6026724137931034,
                    "citycode": "75109",
                    "type": "housenumber",
                    "label": "TEST",
                    "context": "75, Paris, Île-de-France"
                },
                "id": "ADRNIVX_0000000270730878",
                "geometry": {
                    "type": "Point",
                    "coordinates": [0, 0]
                }
            }
        },
        isCommuneGRDF: function isCommuneGRDF(key) {
            _ember.default.Logger.log(key);
            return false;
        },
        actions: {
            showDetail: function showDetail() {
                this.toggleProperty('showDetail');
            }
        },
        sometruc: function sometruc(e) {
            _ember.default.Logger.log(e);
        }
    });
});