define("norme-adresse/helpers/render-pre", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Helper.extend({
    compute: function compute(params /*, hash*/) {
      var test = "BAN";
      if (params[0].substr(0, 1) == "1") {
        test = "Numéro";
      } else if (params[0].substr(0, 1) == "2") {
        test = "Numéro estimé";
      } else if (params[0].substr(0, 1) == "3") {
        test = "Numéro approximatif";
      } else if (params[0].substr(0, 1) == "4") {
        test = "Rue";
      } else if (params[0].substr(0, 1) == "5") {
        test = "Lieu-dit";
      } else if (params[0].substr(0, 1) == "6") {
        test = "Chef lieu";
      } else if (params[0].substr(0, 1) == "7") {
        test = "Code postal";
      } else if (params[0].substr(0, 1) == "8") {
        test = "Ville";
      }
      return test;
    }
  });
});