define('norme-adresse/controllers/index', ['exports', 'ember', 'norme-adresse/config/environment'], function (exports, _ember, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Controller.extend({
        comCtrl: _ember.default.inject.controller('communes'),
        mapCtrl: _ember.default.inject.controller('map'),
        envType: "prod",
        actions: {
            toggleProd: function toggleProd() {
                _ember.default.Logger.log(this.envType);
                this.get('comCtrl').set('connInfo', {
                    "url": _environment.default.APP.PROD_COM_HOST,
                    "key": _environment.default.APP.PROD_KEY,
                    "env": "prod"
                });
                this.get('mapCtrl').set('connInfo', {
                    "url": _environment.default.APP.PROD_MAP_HOST,
                    "key": _environment.default.APP.PROD_KEY,
                    "env": "prod"
                });
                _ember.default.set(this, "envType", "prod");
            },
            toggleGreen: function toggleGreen() {
                _ember.default.Logger.log(this.envType);
                this.get('comCtrl').set('connInfo', {
                    "url": _environment.default.APP.GREEN_COM_HOST,
                    "key": _environment.default.APP.GREEN_KEY,
                    "env": "green"
                });
                this.get('mapCtrl').set('connInfo', {
                    "url": _environment.default.APP.GREEN_MAP_HOST,
                    "key": _environment.default.APP.GREEN_KEY,
                    "env": "green"
                });
                _ember.default.set(this, "envType", "green");
            }
        }
    });
});