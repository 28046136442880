define('norme-adresse/controllers/communes', ['exports', 'ember', 'norme-adresse/config/environment'], function (exports, _ember, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var isEmpty = _ember.default.isEmpty;
    exports.default = _ember.default.Controller.extend({
        init: function init() {
            if (isEmpty(this.connInfo)) {
                this.connInfo = {
                    "url": _environment.default.APP.PROD_COM_HOST,
                    "key": _environment.default.APP.PROD_KEY,
                    "env": "prod"
                };
            }
            this.chosen = { "feature": {
                    "type": "Feature",
                    "geometry": { "type": "Point", "coordinates": [0, 0] },
                    "properties": {
                        "id": "00000", "city": "XXX", "name": "XXX", "type": "XXX",
                        "context": "00, XXX, XXX", "citycode": ["00000"],
                        "postcode": "00000", "adm_weight": -1, "population": -1,
                        "commune_grdf": [], "libelle_acheminement": "XXX" } } };
            var query = 'Avignon 84000';
            var result = this.chosen;
            this.zoom = 13, _ember.default.$.ajax({
                dataType: "json",
                url: this.connInfo.url + query,
                headers: {
                    "x-api-key": this.connInfo.key
                }
            }).then(function (data) {
                if (data.features.length > 0) {
                    _ember.default.set(result, 'feature', data.features[0]);
                } else {
                    _ember.default.set(result, 'feature', this.chosen.feature);
                }
            });
        },
        pop: false,
        chosen: {},
        isCommuneGRDF: function isCommuneGRDF(key) {
            _ember.default.Logger.log(key);
            return false;
        },
        actions: {
            showDetail: function showDetail() {
                this.toggleProperty('showDetail');
            }
        },
        sometruc: function sometruc(e) {
            _ember.default.Logger.log(e);
        }
    });
});