define('norme-adresse/router', ['exports', 'ember', 'norme-adresse/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('map');
    this.route('communes');
  });

  exports.default = Router;
});