define('norme-adresse/helpers/json-line', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Helper.extend({
        replacer: function replacer(match, pIndent, pKey, pVal, pEnd) {
            var key = '<span class=json-key>';
            var val = '<span class=json-value>';
            var str = '<span class=json-string>';
            var r = pIndent || '';
            if (pKey) r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
            if (pVal) r = r + (pVal[0] == '"' ? str : val) + pVal + '</span>';
            return r + (pEnd || '');
        },
        prettyPrint: function prettyPrint(obj) {
            var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
            return JSON.stringify(obj, null, 3).replace(/&/g, '&amp;').replace(/\\"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(jsonLine, this.replacer);
        },
        compute: function compute(params /*, hash*/) {
            var line = "<div class='jsonline'><span class='json-key'>";
            line = line + params[0] + ': </span>';
            if (_ember.default.typeOf(params[1][0]) === "object") {
                line = line + "<span class='sublvl'>" + this.prettyPrint(params[1]) + '</span>';
            } else if (_ember.default.typeOf(params[1]) === "object") {
                line = line + this.prettyPrint(params[1]);
            } else {
                line = line + (params[1][0] == '"' ? '<span class=json-string>' : '<span class=json-value>') + (params[1] + "").replace(/,/g, ', ') + '</span>';
            }
            line = line + '</div>';
            return new _ember.default.String.htmlSafe('<pre  id=\"' + params[0] + '\">' + line + '</pre>');
        }
    });
});