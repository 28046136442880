define('norme-adresse/helpers/test', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Helper.extend({
        compute: function compute(params /*, hash*/) {
            return params[0] == params[1];
        }
    });
});